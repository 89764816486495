/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import 'styles/css/bootstrap.min.css'
import 'styles/css/flaticon.css'
import 'styles/css/remixicon.css'
import 'react-tabs/style/react-tabs.css'
import './node_modules/aos/dist/aos.css'
// import 'react-accessible-accordion/dist/fancy-example.css';
import 'swiper/css'
import 'swiper/css/bundle'

// Global Styles
import 'styles/global.css'
import 'styles/css/style.css'
import 'styles/css/responsive.css'
import React from "react";

export const wrapPageElement = ({ element, props }) => {
  const pageElement = <element.type {...props} key={props.pageResources.page.path} />

  return (
    <>
      {pageElement}
    </>
  );
};